<template>
  <div class="view">
    <app-header title="Ingame - Project"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <router-link class="control-bar__button"
                     :title="__('component.files.file_browser.navigate_up')"
                     :to="{name: 'ingame_projects'}"
                     v-slashes
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-left.svg"
               alt="back"/>
        </router-link>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button"
             v-if="hasEditRights"
             :title="__('common.edit')" @click="editProject(project)" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/pencil.svg"
               alt="edit"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <div class="layout layout--wrap project" v-if="project">
        <div class="flex xs12 sm12 md4">

          <div class="project__name">{{ project.name }}</div>

          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6">
              <div class="tile">
                <div class="project__label">{{ __('entity.common.project_type') }}</div>
                <div class="">
                  {{ project.projectType.name }}
                </div>
              </div>
            </div>
            <div class="flex xs12 sm6">
              <div class="tile">
                <div class="project__label">{{ __('entity.ingame.project.access') }}</div>
                <ul class="futuristic" v-if="project.projectAccesses.length > 0">
                  <li v-for="projectAccess in project.projectAccesses">{{ projectAccess.role.name }}</li>
                </ul>
                <div v-else>{{ __('public') }}</div>
              </div>
            </div>
          </div>

          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6">
              <div class="tile">
                <div class="project__label">{{ __('entity.ingame.project.in_charge') }}</div>
                <div class="project__user">
                  <div class="project__user-avatar" :style="{backgroundImage: `url(${project.inCharge.avatar})`}"></div>
                  <div class="project__user-name">{{ project.inCharge.userName }}</div>
                </div>
              </div>
            </div>
            <div class="flex xs12 sm6">
              <div class="tile">
                <div class="project__label">{{ __('entity.ingame.project.contributors') }}</div>
                <div class="project__user" v-for="contributor in project.contributors">
                  <div class="project__user-avatar" :style="{backgroundImage: `url(${contributor.user.avatar})`}"></div>
                  <div class="project__user-name">{{ contributor.user.userName }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="tile project__description mt bodytext" v-html="project.description"></div>
        </div>
        <div class="flex xs12 sm12 md8">
          <project-swiper ref="projectSwiper" :project-images="project.projectImages"/>
        </div>
      </div>
    </div>
    <edit-project ref="editProject" @updated="refresh" @created="editProject"/>
  </div>
</template>

<script>
import apiSecured from '../../api/secured';
import AppHeader from '../../components/AppHeader';
import ProjectTiles from '../../components/Ingame/Project/ProjectTiles';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { pageTitleMixin } from "../../mixins/pageTitle";
import ProjectSwiper from "../../components/Ingame/Project/ProjectSwiper";
import EditProject from "../../components/Ingame/EditProject";
import { userMixin } from "../../mixins/user";

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
  components: {
    EditProject,
    ProjectSwiper,
    AppHeader,
    ProjectTiles
  },
  data: () => ({
    loading: false,
    project: null,
    projectId: null
  }),
  watch: {
    projectId() {
      this.refresh();
    }
  },
  computed: {
    hasEditRights() {
      if (this.hasOneRoles([])) {
        return true;
      }
      return this.$store.state.user.id === this.project.inChargeId;
    }
  },
  methods: {
    addProject() {
      this.$refs.editProject.showCreate();
    },
    editProject(project) {
      this.$refs.editProject.showEdit(project);
    },
    refresh() {
      this.loading = true;
      apiSecured.get(`/ingame/projects/project/${this.projectId}`).then(async (res) => {
        this.project = await this.enrichRecords(res.data);
        this.$nextTick(() => this.$refs.projectSwiper.initSwiper());
        this.setPageTitle(this.project.name + ' - Projects');
        this.loading = false;
        this.showInfoNotification('Project loaded');
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async enrichRecords(project) {
      let projectImages = [];

      await apiSecured.get(`/ingame/projects/projecttype/${project.projectTypeId}`, {
        params: {
          fields: 'id,name,color'
        },
      }).then((res) => {
        project.projectType = res.data;
        this.showInfoNotification('Project type have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const userIds = [project.inChargeId, ...project.contributors.map(contributor => contributor.userId)];

      await apiSecured.get(`/community/user/(${userIds.join(',')})`, {
        params: {
          fields: 'id,avatar,username'
        },
      }).then((res) => {
        project.inCharge = res.data.find(user => user.id === project.inChargeId);
        project.contributors.map(contributor => {
          contributor.user = res.data.find(user => user.id === contributor.userId);
          return contributor;
        })
        this.showInfoNotification('User have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const params = {
        orderBy: 'sorting asc'
      }
      await apiSecured.get(`/ingame/projects/projectimage/by-project/${project.id}`, { params }).then((res) => {
        projectImages = res.data
        this.showInfoNotification('Project images have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const fileIds = projectImages.map(projectImage => projectImage.fileId);
      if (fileIds.length > 0) {
        await apiSecured.get(`/fal/file/(${fileIds.join(',')})`).then((res) => {
          projectImages = projectImages.map(projectImage => {
            projectImage.file = res.data.find(file => file.id === projectImage.fileId);
            return projectImage;
          });
          this.showInfoNotification('Files have been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
        project.projectImages = projectImages;
      }

      const roleIds = project.projectAccesses.map(projectAccess => projectAccess.roleId);
      if (roleIds.length > 0) {
        await apiSecured.get(`/identity/role/(${roleIds.join(',')})`).then((res) => {
          project.projectAccesses = project.projectAccesses.map(projectAccess => {
            projectAccess.role = res.data.find(role => role.id === projectAccess.roleId);
            return projectAccess;
          })
          this.showInfoNotification('Project images have been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      }

      return project;
    }
  },
  created() {
    this.projectId = this.$route.params.id;
  }
}
</script>
