<template>
  <div class="swiper-container swiper-container--full-height">
    <div class="swiper-wrapper">
      <template v-for="projectImage in projectImages">
        <div
            class="swiper-slide"
            :key="projectImage.file.filename"
            :style="renderBackgroundImage(projectImage)"
        >
          <video controls v-if="projectImage.file.type === 'mp4'" class="swiper-slide-video" preload="none"
                 :poster="renderPoster(projectImage)">
            <source :src="projectImage.file.uri" type="video/mp4"/>
          </video>
          <div class="swiper-slide-info">
            <h2 v-if="projectImage.file.name">{{ projectImage.file.name }}</h2>
            <h2 v-else>{{ projectImage.file.filename }}</h2>
          </div>
        </div>
      </template>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
  import Swiper, {Navigation, Pagination} from 'swiper';

  Swiper.use([Navigation, Pagination]);

  export default {
    name: "ProjectSwiper",
    props: {
      projectImages: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      swiperOptions: {
        autoHeight: false,
        effect: 'slide',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: function () {
            Array.from(document.getElementsByClassName('swiper-slide-video')).forEach(video => {
              video.pause();
            })
          }
        }
      },
      swiperInstance: null
    }),
    methods: {
      initSwiper() {
        if(this.swiperInstance) {
          this.swiperInstance.destroy();
        }
        this.swiperInstance = new Swiper('.swiper-container', this.swiperOptions);
      },
      renderBackgroundImage(projectImage) {
        if (projectImage.file.type !== 'mp4') {
          return { 'background-image': `url('${projectImage.file.uri}')` }
        }
        return {};
      },
      renderPoster(projectImage) {
        return projectImage.file.thumbnailUri;
      },
    },
    created() {
    }
  }
</script>